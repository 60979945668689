import { NextPageWithLayout } from "@/components/layout";
import { useAuth } from "@/contexts/auth";
import { useRouter } from "next/router";
import { useEffect } from "react";

const IndexPage: NextPageWithLayout = () => {
  const router = useRouter();
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      router.push("/princess/dashboard");
    } else {
      router.push("/signIn");
    }
  }, [user]);
  return <></>;
};

export default IndexPage;
